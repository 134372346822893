document.addEventListener('DOMContentLoaded', () => {
	const openingHoursTable = document.querySelectorAll('.opening-hours-table');

	openingHoursTable?.forEach((table) => {
		const thisWeek = table.querySelector('.this-week');
		const days     = thisWeek?.querySelectorAll('tr');
		const currentDayIndex = new Date().getDay();
		const dayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
		const currentDay = dayNames[currentDayIndex];
		
		days?.forEach((day) => {
			const dayName = day.getAttribute('data-day');
			if (dayName === currentDay) {
				day.style.color = '#169FDB';
				day.style.fontWeight = 'bold';
			}
		});
	});
});
